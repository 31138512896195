
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















































































.upload-panel {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-top, (xxs: 50px, xxl: 100px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 50px, xxl: 100px));
}
#profile-pic-demo .drop-help-text {
  display: none;
}
#profile-pic-demo .is-drag-over .drop-help-text {
  display: block;
}
#profile-pic-demo .profile-pic-upload-block {
  border: 2px dashed transparent;
  margin-bottom: 15px;
}

#profile-pic-demo .is-drag-over.profile-pic-upload-block {
  border-color: #aaa;
}
#profile-pic-demo .vue-file-agent {
  width: 180px;
  float: left;
  margin: 0 15px 5px 0;
  border: 0;
  box-shadow: none;
}

.input {
  position: relative;
}
.box {
  border: 1px solid $c-main-primary;
  margin-top: 1em;
  padding: 30px;
}

.upload-form {
  .form-item {
    display: block;
  }

  label {
    display: block;
  }

  .form-item-radio {
    label {
      display: inline-flex;
      margin-right: 1rem;
    }
  }
}
.submit-row {
  text-align: right;
  padding-right: 1em;
}
